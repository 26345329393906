import { FC } from 'react';

import { Head } from '@dxp-next';
import { useContent } from '@sitecore/common';
import { MetaDataRendering } from '@sitecore/types/manual/MetaData';

const MetaData: FC<MetaDataRendering> = () => {
  const { fields } = useContent<MetaDataRendering>();

  return (
    <Head>
      {fields.map((property, index) => {
        if (!property?.tag) return null;

        const TagName = property.tag;
        return <TagName key={index} {...(property?.attributes ?? {})} />;
      })}
    </Head>
  );
};

export default MetaData;
